<template>
    <div class="container H_100vh bg_fff PLR_3">
       
        <div class="flex_row_between PTB_4">
            <van-icon  @click="$router.go(-1)" name="arrow-left" size=".4rem" />
            <div   @click="del">
                <span class="edit">删除</span>
            </div>
        </div>
        <div class="P_2">
            <div class="flex_rowL  PB_4">
                <span class="color_333 PR_2 fontS_17 font_weight_bold">{{obj.skillname}}</span>
                <van-icon v-if="list.length>0" name="passed" class="color_F44C06" size=".5rem" />
            </div>
            <div class="PB_4  PLR_2 BB_1_D6D6D6 flex_rows_start">
                <div class=" item3 PTB_1 PLR_2 textC MR_2 bg_FFEDE4 color_F44C06 B_radius_max font_weight_bold">{{obj.type == 1 ? "工作技能" : (obj.type==2 ? "生活技能":(obj.type == 3 ? "其他技能" : "自定义技能" )  )}}  </div>
                <div  class="item3 PTB_1 PLR_2 textC MR_2 bg_FFEDE4 color_F44C06 B_radius_max font_weight_bold">{{mim_id == 0 ? "自我创建" : "企业创建"}}</div>
                <div  class="item3 PTB_1 PLR_2 textC MR_2 bg_FFEDE4 color_F44C06 B_radius_max font_weight_bold">认可.{{list.length}}</div>
            </div>                                                                        

            <div>
                <div class="textL color_333 PTB_4 PR_2 fontS_17 font_weight_bold">
                    添加人
                </div>
                <div class="flex_rowL">
                    <img v-if="obj.headimg_url == '' || obj.headimg_url == null " style="width:1rem;height:1rem;margin-right:0.2rem;border-radius:50%;" src="../../assets/user_1.jpg" alt="">
                    <img v-else style="width:1rem;height:1rem;margin-right:0.2rem;border-radius:50%;" :src="obj.headimg_url" alt="">
                    <div v-if="mim_id != 0" class="flex_grow_1 textL PB_3 BB_1_D6D6D6">
                        <div class="color_333 fontS_15 font_weight_bold PTB_2">{{obj.company_name == "" ? "暂无最新入职" :obj.company_name }}</div>
                        <div class="color_999">{{obj.username== null ? "暂无名称" : obj.username}}</div>
                    </div>
                    <div v-else style="color:#999;font-size:0.3rem;">{{obj.username == null ? "暂无名称" : obj.username}}</div>
                    <div v-if="mim_id == 0" class="person">个人</div>
                    <div v-else class="person">企业</div>
                </div>
            </div>
            <div class="textL color_333 PTB_4 PR_2 fontS_17 font_weight_bold">
                认可人
            </div>
            <div v-if="list.length>0">
                <div class="flex_rowL MB_2" v-for="(item,index) in list" :key="index">
                    <img v-if="item.sex == 2 " style="width:1rem;height:1rem;margin-right:0.2rem;border-radius:50%;" src="../../assets/nv.jpg" alt="">
                    <img v-else style="width:1rem;height:1rem;margin-right:0.2rem;border-radius:50%;" src="../../assets/user_1.jpg" alt="">
                    <div class="flex_grow_1 textL PB_3 BB_1_D6D6D6">
                        <div class="flex">
                            <div class="color_333 fontS_15 font_weight_bold PTB_2">{{item.realname}}</div> 
                            <div  class="color_999">{{item.add_time}}</div>
                        </div>
                       
                        <div class="color_999">{{item.company_name}}</div>
                    </div>
                </div>
            </div>
            <div v-else class="PT_5 textC">
                <img style="width:2rem;height:1.7rem;" src="../../assets/noPerson.png" alt="">
                <div class="txt">- 很抱歉，还未有人认可你的技能 -</div>
            </div>
            <div class="del" @click="goback">返回</div>
        </div>
        <!-- <van-loading v-if="showLoading" size="45" class="loading" type="spinner" /> -->
    </div>
</template>

<script>
    import '@/assets/css/common.css';
import { Toast, Dialog  } from 'vant';


    export default {
        name: "",
        data() {
            return {
                // title: '搜索'
                noPerson:null, //判断是否有人认可
                // skillname:"",//工作技能名称
                // type:"",// 工作技能类型
                obj:{},
                list:[],
                item:{},
                mim_id:''
                // isEdit:true
            }
        },
        created() {
        },
        mounted() {
            let {mim_id, id, skillname, type, company_name,headimg_url,username, }= this.$route.query
           console.log( username)
            let obj = {}
            obj.skillname = skillname 
            obj.type = type
            obj.company_name = company_name
            obj.headimg_url = headimg_url
            obj.username = username
            this.obj = obj
            // console.log(obj)
            this.mim_id = mim_id
            let type1 ;
            let type3 = this.$route.query.type 
            if(type3 == 1 || type3 ==2){
                // 非自定义
                type1 = 1
             
            }else{
                // 自定义
                type1 = 2
            }
            
            this.$http.post("/user/v1/Position/acceptList",{reqType:"acceptList",skill_id:id,type:type1})
            .then((res)=>{
                let response = JSON.parse(res.data)
                 console.log(response)
                if(response.code == 0){
                    this.list = response.data 
                }
            })
            .catch((e)=>{console.log(e)})
        },
        methods: {
            goback(){
                this.$router.go(-1)
            },
            del(){
                Dialog.confirm({
                    title: '标题',
                    message: '是否忍心删除该技能 删除操作不可撤销，相关认可记录将一并删除',
                })
                .then(() => {
                    // on confirm
                    let type = this.$route.query.type
                    let type1;
                    console.log(type)
                    if(type == 1 || type ==2 ){
                         // 非自定义
                        type1 = 1
                    }else{
                         // 自定义
                        type1 = 2
                    }
                
                    this.$http.post("/user/v1/Position/delete",{reqType:"delete",id:this.$route.query.id,type:type1})
                    .then((res)=>{
                        res = JSON.parse(res.data)
                        console.log(res)
                        if(res.code == 0){
                            Toast.success("删除成功")
                            setTimeout(()=>{
                                this.$router.push("./onlineTag")
                            },1000)
                        }else{
                            Toast.fail(res.msg)
                        }
                    })
                })
                .catch(() => {
                    // on cancel
                });
               
            },
            edit(){
                if(this.list.length>0){
                   Toast("已有认可人,暂不可修改")
                   return
                }
                let type = this.$route.query.type
                let id = this.$route.query.id
                let skill_name = this.$route.query.skillname
                if(type == 1 || type ==2 || type == 3){ 
                    
                    this.$router.push({path:"./editTag",query:{ptype:type,id:id,skill_name:skill_name}})
                }else{
                    
                    this.$router.push({path:"/divTagEdit",query:{id:id}})
                }
               
            }
        },
        components: {

        }
    }
</script>

<style scoped>
.person{

    background:#00beff;
    text-align: center;
    line-height: 0.35rem;
    color:#fff;
    margin-left:0.2rem;
    border-radius: 0.1rem;
    font-size:0.2rem;
    padding:0 0.15rem;
}
.flex{
    display:flex;
    justify-content: space-between;
    align-items: center;
}
.item3{
    padding:0 0.3rem;
    height: 0.79rem;
    line-height:  0.79rem;
    font-size:0.25rem;
}
.del{
  width: 6rem;
  height: 0.96rem;
  background: linear-gradient(90deg, #f55613 0%, #fc9e46 100%);
  border-radius: 0.48rem;
  font-size: 0.36rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  line-height: 0.96rem;
 
  margin: 1.5rem auto 0;
}
.edit{
    font-size: 0.34rem;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #333333;
}
.txt{
    font-size: 0.28rem;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #333333;
}
.container{
    position: fixed;
  height:100%;
  width:100%;
  background-color: #fff;
  top: 0;
  bottom:0;
  overflow-y:scroll;
  overflow-x:hidden;
}
</style>